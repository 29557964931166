<template>
  <div class="a_top">
    <div class="at_left">
      <a @click="getClick">米悦首页</a>
      <span
        v-if="item!='米悦首页'"
        v-for="(item,index) in titles"
        :key="index"
      >{{titles.length==index+1?' / ':''}}{{item}}</span>
      <!-- {{this.titles.toString().replace(',',' / ')}} -->
    </div>
    <div
      class="at_right"
      v-if="show"
    >
      发布时间：2023-10-27
      <!-- {{ time }} -->
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: [],
      time: ""
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    $route: {
      handler: function(val, oldVal) {
        // console.log("xin", val);
        this.titles = val.meta.title;
        // console.log(this.titles.toString());
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getTime();
  },
  methods: {
    getClick(){
      this.$router.push("/");
    },

    getTime() {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      this.time = `${hours}:${minutes}:${seconds}`;
      setInterval(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDay();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        this.time = `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped >
.a_top {
  margin: 0 auto;
  width: 120rem;
  padding: 2rem 0;
  border-bottom: 0.1rem solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .at_left {
    font-size: 1.6rem;
    font-weight: 400;
    color: #999999;
    a {
      text-decoration: none;
      font-size: 1.6rem;
      font-weight: 400;
      color: #999999;
    }
    a:hover {
      color: red;
    }
  }
  .at_right {
    font-size: 1.6rem;
    font-weight: 400;
    color: #999999;
  }
}
</style>
