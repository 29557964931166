<template>
  <div class="about">
    <img class="a_Image" src="@/assets/img/about_banner.png" />
    <!-- <img class="a_twoImage" src="@/assets/img/about_phone_banner.png" alt=""> -->
    <ContentTop></ContentTop>
    <div class="a_content">
      <div class="ac_title">关于米悦</div>
      <div class="ac_content">米悦朝鲜族拌饭创立于2023年，由黄庆飞先生创办，并由北京四有青年餐饮创业服务集团全力孵化与运营。自品牌成立以来，米悦迅速扩展，目前在全国已拥有180家门店，成为国内餐饮行业中备受瞩目的新锐品牌。 </div>
        <div class="ac_content">米悦的运营中心位于上海、合肥和石家庄，配有七个仓储物流中心，并由超过百人的专业团队进行管理与支持。品牌通过“品牌孵化+合作经营+总部管理+持续服务”的创新商业模式，致力于为餐饮创业者提供全方位的支持，成为中国领先的新餐饮创业服务平台。 </div>
          <div class="ac_content">米悦以其丰富多样的菜品而广受欢迎，其中经典石锅拌饭每月销量超过6000碗，成为招牌畅销品。其他备受推崇的美食还包括烤牛肉拌饭、香辣鱿鱼、石板豆腐、五花肉泡菜锅、辣椒炒肉及招牌安格斯肥牛等。这些菜品以其独特的口味和高品质原料，深受消费者的喜爱，迅速赢得市场口碑</div>
    </div>
    <!-- <div class="i_bottom"> -->
    <Bottom
      @getTop="getTop"
      :hasScroll="hasScroll"
    ></Bottom>
    <!-- </div> -->
  </div>
</template>

<script>
import ContentTop from "../../components/content_top.vue";
import Bottom from "@/components/bottom";

export default {
  name: "aboutIndex",
  components: {
    ContentTop,
    Bottom
  },
  data() {
    return {
      hasScroll: false
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    getTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // 平滑滚动
      });
    },
    handleScroll() {
      this.hasScroll = window.pageYOffset > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 1670px) {
  .i_bottom {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 1200px) {
  .ac_content{
    line-height: 7.6rem !important;
  }

  .a_Image{
    height: 40rem !important;
  }
}

.about {
  height: 100%;

  img {
    width: 100%;
    height: 50rem;
    background: #eeeeee;
  }
  .a_content {
    margin: 0 auto 2.5rem;
    width: 120rem;
    text-align: center;
    .ac_title {
      font-size: 3.6rem;
      font-weight: 400;
      color: #000000;
      margin: 5rem 0;
    }
    .ac_content {
      text-align: left;
      font-size: 2rem;
      font-weight: 400;
      color: #666666;
      line-height: 3.6rem;
      text-indent: 4rem;
    }
  }
}
</style>